$z:#2dc19f;
body
{
	font-size: 14px;
	font-family: 'Microsoft YaHei';
	overflow-x:hidden;
}
body,ul,dl,ol
,h1,h2,h3,h4,h5,h6,
p,b
{
	padding: 0;
	margin: 0;
	border:0;
	font-weight: 400;
}
button,input{
	padding: 0;
	color: inherit;
	outline: none;
	border:none;
	background: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
button
{
	cursor: pointer;
}
select{
	border:none;
}
textarea
{
	padding: 0;
	color: inherit;
	outline: none;
	border:none;
	background: none;
	-webkit-appearance: none;
}
.none
{
	display: none;
}
a{
	color: inherit;
	text-decoration:none;
}
ul li,ol li{
	list-style-type:none;
}
img{
	display: block;
	width: 100%;
}
em,i
{
	font-style: normal;
}
.err
{
	position: fixed;
	height: 40px;
	padding: 0 20px;
	line-height: 40px;
	text-align: center;
	color: #fff;
	left: 50%;
	bottom:100px;
	min-width: 80%;
	transform:translate(-50%,0);
	z-index:109;
	background:rgba(0,0,0,0.7);
	border-radius: 5px;
}
.shade
{
	background:rgba(0,0,0,0.7);
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index:101;
}
.shade2
{
	background:rgba(0,0,0,0);
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index:101;
}

header
{
	position: fixed;
	width: 100%;
	height:80px;
	top: 0;
	left: 0;
	z-index: 99;
	background: rgba(0,0,0,1);
	box-shadow: 0 1px 1px rgba(0,0,0,0.2);
	font-size: 16px;
	.main
	{
		max-width: 1200px;
		width:100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform:translate(-50%,-50%);
		display: flex;
		justify-content:space-between;
		align-items:center;
		.list
		{
			li.logo
			{
				font-size: 30px;
				color:#fff;
				img
				{
					height: 50px;
					width: 50px;
				}
			}
			li.sel
			{
				color: $z;
			}
			li
			{
				display: inline-block;
				vertical-align: middle;
				margin-right:20px;
				color: #999;
				a
				{
					padding: 10px 12px;
				}
				a:hover
				{
					background: #000;
					color: #fff;
				}
			}
		}
		.userCart
		{
			font-size: 14px;
			color: #999;
			button
			{
				.moreAh
				{
					position: absolute;
					left: 0;
					top: 32px;
					background: #fff;
					display: none;
					width:100%;
					a
					{
						display: block;
						height: 30px;
						line-height: 30px;
					}
					a:hover
					{
						background: #eee;
					}
				}
			}
			button:hover
			{
				.moreAh
				{
					display: block;
				}
			}
			button,>a
			{
				display: inline-block;
				vertical-align: middle;
				padding: 4px 10px;
				margin-left: 20px;
				position: relative;
				.iconfont
				{
					font-size: 18px;
				}
				span
				{
					top: -5px;
					right: -5px;
					color: #fff;
					background: #da030b;
					border-radius: 50%;
					display: block;
					width:18px;
					height:18px;
					line-height: 18px;
					position: absolute;
					text-align: center;
				}
			}
		}
	}
}

.store
{
	margin-top: 80px;
	.ptu_4
	{
		text-align: center;
		padding:80px 0;
		
		
		.list
		{
			padding: 0 30px;
			display:flex;
			justify-content:space-between;
			li
			{
				width: calc((100% - 90px)/4);
			}
		}
	}
	.ptuList
	{
		max-width: 1440px;
		width: 100%;
		margin: 0 auto;
		li
		{
			display:flex;
			align-items:center;
			.img,
			.info
			{
				flex:1;
			}
			.info
			{
				text-align: center;
				h1
				{
					font-weight: 700;
					font-size: 40px;
				}
				p
				{
					font-size: 24px;
					padding:30px 0;
				}
				.btn
				{
					display: inline-block;
					vertical-align: middle;
					font-size: 20px;
					padding: 20px 0;
					a
					{
						margin: 0 20px;
						line-height: 34px;
						padding: 10px 15px;
						transition:0.3s;
					}
					a.r:hover
					{
						color: #fff;
						background: $z;
					}
					a.l
					{
						text-decoration: underline;
					}
					a.r
					{
						border:1px solid $z;
						color: $z;
					}
				}
			}	
		}
	}
}
footer
{
	width: 100%;
	background: #282828;
	padding: 50px 0;
	.info
	{
		max-width: 1200px;
		margin: 0 auto;
		padding: 0 50px;
		display:flex;
		justify-content:space-between;
		font-size:16px;
		color: #fff;
		box-sizing:border-box;
		dl
		{
			dt:nth-of-type(1)
			{
				padding-bottom: 20px;
			}
		}
		.m
		{
			font-size: 16px;
			color: #999;
		}
		a
		{
			margin-right: 20px;
		}
		.r
		{
			text-align: right;
		}
	}
}
@media screen and(min-width:1200px)
{
	.yngjian_pc
	{
		.ptu_2
		{
			li
			{
				display: flex;
			}
		}
	}
}
@media screen and(max-width:1200px)
{
	.yngjian_pc
	{
		.ptu_2
		{
			.info
			{
				text-align: center;
				padding: 50px 0 !important;
			}
		}
	}
}
.yngjian_pcBox
{
	margin-top: 80px;
}
.yngjian_pc
{
	width:100%;
	max-width: 1200px;
	margin:20px auto;
	.ptu_2
	{
		width: 100%;
		li
		{
			background: #fff;
			margin-bottom: 20px;
			align-items:center;
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
			h1
			{
				font-size: 30px;
				font-weight: 70;
			}
			p
			{
				font-size: 22px;
				padding: 20px 0;
				color: #666;
			}
			.price
			{
				color: #e51c23;
				font-size: 24px;
				padding-top: 20px;
				margin-bottom:40px;
			}
			a
			{
				padding: 8px 15px;
				text-decoration: underline;
				font-size: 20px;
			}
			>img
			{
				flex:2.5;
			}
			.info
			{
				flex:1;
				padding:0 60px;
				box-sizing:border-box;
			}
		}
	}
	.list
	{
		overflow: hidden;
		h3
		{
			height:60px;
			line-height:60px;
			font-size:30px;
			display: flex;
			justify-content:space-between;
			align-items:center;
			a
			{
				font-size: 18px;
				color: $z;
			}
		}
		li
		{
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
			background: #fff;
			margin-bottom: 20px;
			h1
			{
				font-size:18px;
				padding: 0 12px;
				margin: 10px 0;
				display: -webkit-box;
				-webkit-box-orient:vertical;
				overflow: hidden;
				text-overflow:ellipsis;
				-webkit-line-clamp:1;
			}
			s
			{
				color: #666;
			}
			dl
			{
				display:flex;
				padding:0 12px  20px;
				align-items:center;
				justify-content:space-between;
				p
				{
					color: #666;
					display: -webkit-box;
					-webkit-box-orient:vertical;
					overflow: hidden;
					text-overflow:ellipsis;
					-webkit-line-clamp:1;
				}
				.price
				{
					color: #e51c23;
					font-size:18px;
					margin-top: 5px;
				}
				.addCart
				{
					margin-top: 5px;
					color: #fff;
					padding: 5px 12px;
					border-radius:2px;
					white-space: nowrap;
					font-size: 22px;
					color: #e51c23;
				}
			}
		}
		li:nth-of-type(3n)
		{
			margin-right: 0;
		}
	}
}
@media screen and(min-width:600px)
{
	.yngjian_pc
	{
		.list
		{
			li
			{
				float: left;
				margin-right: 20px;
				width: calc((100% - 40px)/3);
			}
		}
	}
}
@media screen and(max-width:600px)
{
	.yngjian_pc
	{
		.list
		{
			li
			{
				width: 500px;
				width:100%;
			}
		}
	}
}
.labsBox
{
	display:flex;
	width: 100%;
	justify-content:space-between;
	align-items:center;
	margin-top: 20px;
	button
	{
		text-align: center;
		cursor: pointer;
		background: #fff;
		padding: 30px 0;
		width: calc((100% - 60px)/3);
		box-sizing:border-box;
		box-shadow: 0 0 5px rgba(0,0,0,0.2);
		i
		{
			font-size:50px;
		}
		h3
		{
			font-size:26px;
		}
		p
		{
			font-size: 16px;
			color: #666;
			padding-top: 10px;
		}
	}
	button.sel
	{
		border:2px solid $z;
	}
}
.baodan_pcBox
{
	margin-top: 80px;
}
.baodan_pc
{
	width: 100%;
	max-width:900px;
	margin:20px auto;
	border-top: 1px solid rgba(0,0,0,0);
	min-height: calc(100vh - 324px);
	.services
	{
		li
		{
			margin-top: 10px;
			background: #fff;
			box-shadow: 0 0px 5px rgba(0,0,0,.2);
			.name
			{
				color: #e51c23;
				height: 40px;
				line-height: 40px;
				padding:0 12px;
			}
			.info
			{
				border-top: 1px solid #eee;
				padding:10px 12px;
				border-bottom: 1px solid #eee;
				h3
				{
					padding: 4px 0;
					font-size: 14px;
				}
				p
				{
					color: #666;
					font-size: 12px;
				}
			}
			.btns
			{
				text-align: right;
				padding: 10px 12px;
				button
				{
					background: $z;
					padding:8px 14px;
					border-radius:2px;
					min-width:70px;
					color: #fff;
				}
			}
		}
	}
	
	.baodan
	{
		li
		{
			background: #fff;
			margin-top: 10px;
			border-bottom: 1px solid rgba(0,0,0,0);
			h3
			{
				height: 40px;
				line-height: 40px;
				border-bottom: 1px solid #eee;
				font-size: 14px;
				padding: 0 12px;
			}
			textarea
			{
				height:100px;
				padding:10px 12px;
				width: 100%;
				box-sizing:border-box;
				width: 100%;
				resize:none;
			}
			.imgs
			{
				display: flex;
				margin:30px 20px;
				dt
				{
					width:150px;
					height:150px;
					overflow: hidden;
					position: relative;
					margin-right:20px;
					input
					{
						top: 0;
						left: 0;
						position: absolute;
						width: 100%;
						height: 100%;
						opacity: 0;
					}
					.del
					{
						top:4px;
						right:4px;
						font-size: 20px;
						position: absolute;
						width:30px;
						height:30px;
						text-align: center;
						line-height: 30px;
						z-index:11;	
						cursor: pointer;
					}
					.loading
					{
						top: 0;
						left: 0;
						position: absolute;
						width: 100%;
						height: 100%;
						z-index:9;
						background:rgba(0,0,0,0.5) url(../imgs/loading.gif) center center no-repeat;
						background-size:30px 30px;
					}
				}
			}
			.time
			{
				height: 40px;
				line-height: 40px;
				padding: 0 12px;
				display: flex;
				justify-content:space-between;
			}
			.lianxi
			{
				input
				{
					height: 40px;
					padding: 0 12px;
					box-sizing:border-box;
					width: 100%;
					border-bottom: 1px solid #eee;
				}
			}
		}
	}
	.messageList
	{
		text-align: center;
		.fx
		{
			display: flex;
		}
		li
		{
			background: #fff;
			margin-top: 10px;
			height: 44px;
			line-height: 44px;
			em
			{
				flex:1;
			}
			em.red
			{
				color: red;
			}
			em.yellow
			{
				color: yellow;
			}
			em.green
			{
				color: $z;
			}
		}
	}
	.intruduction
	{
		padding: 12px 80px 12px 12px;
		background: #fff;
		position: relative;
		margin-bottom: 10px;
		h3
		{
			color:#000;
			font-size: 14px;
		}
		em
		{
			display: block;
			color: #c10536;
			font-size: 16px;
			margin-top: 5px;
		}
		.shadeBtn	
		{
			position: absolute;
			top: 50%;
			right:12px;
			padding:6px 12px;
			z-index: 1;
			font-size:20px;
			transform:translate(0,-50%);
			border-radius: 2px;
		}
		.shadeBtn:active
		{
			background: #eee;
		}
	}
	.content
	{
		margin-bottom:10px;
		padding: 20px 12px;
		background: #fff; 
	}
	.infoMain
	{
		background: #fff;
		>.name
		{
			height: 44px;
			line-height: 44px;
			padding-left: 12px;
			border-bottom: 1px solid #eee;
		}
		a
		{
			display: block;
			height: 44px;
			padding: 0 12px;
			float: right;
			font-size: 12px;
			color: $z;
		}
	}
	.details
	{
		margin-top: 10px;
		background: #fff;
		.name
		{
			height: 44px;
			line-height: 44px;
			text-align: center;
		}
	}
}
.success
{
	text-align: center;
	padding: 40px 0;
	i
	{
		font-size:60px;
		color: $z;
	}
	p
	{
		padding: 10px 0;
	}
	a
	{
		display: block;
		text-decoration: underline;
		color: $z;
		padding: 10px 0;
		margin-top: 20px;
	}
}
.seumitBtn
{
	min-width:220px;
	height: 44px;
	background: $z;
	color: #fff;
	border-radius: 10px;
	margin: 20px auto 50px; 
	display: block;
	cursor: pointer;
}
.jinduTime
{
	max-width: 900px;
	margin:100px auto 40px;
	.info_st
	{
		background: #fff;
		padding: 40px;
		box-sizing:border-box;
		margin-top: 20px;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
		.tit
		{
			padding: 20px 12px;
			border-bottom: 1px solid #eee;
		}
		ul
		{
			background: #fff;
			width: calc(100% - 40px);
			margin:0 12px 0 28px;
			box-sizing:border-box;
			border-left: 1px solid $z;
			color:#666;
			font-size: 12px;
			padding: 20px 0;
			li
			{
				padding: 0px 0 0 30px;
				margin-top: 30px;
				position: relative;
				p
				{
					margin-top:5px;
					a
					{
						color: $z;
						margin-left:4px;
					}
				}
				em
				{
					color: $z;
				}
			}
			li:after
			{
				content: '';
				display: block;
				width: 8px;
				height: 8px;
				border:1px solid $z;
				border-radius: 50%;
				left: -6px;
				position: absolute;
				background: #fff;
				top: 0;
			}
		}
	}
}
.news_pcBox	
{
	margin-top: 80px;
}
.news_pc
{
	margin:20px auto 40px;
	max-width: 1200px;
	width: 100%;
	box-sizing:border-box;
	padding: 0 20px;
	min-height: calc(100vh - 242px);
	background: #fff;
	li
	{
		display:flex;
		justify-content:space-between;
		padding:30px 0;
		border-bottom: 1px solid #eee;
		div.oimg
		{
			width: 30%;
		}
		dl
		{
			width: 66%;
			dt:not(:nth-last-of-type(1))
			{
				margin-bottom: 20px;
			}
			dt.txt
			{
				display:-webkit-box;
				-webkit-box-orient:vertical;
				-webkit-line-clamp:4;
				overflow: hidden;
				text-overflow:ellipsis;
				color: #666;
				font-size: 14px;
			}
			dt
			{
				font-size: 18px;
			}
			.name
			{
				font-size:18px;
				font-weight: 700;
				display:-webkit-box;
				-webkit-box-orient:vertical;
				-webkit-line-clamp:2;
				overflow: hidden;
				text-overflow:ellipsis;
			}
			dt.d
			{
				display:flex;
				justify-content:space-between;
				align-items:center;
				margin-top: 30px;
				a
				{
					border:1px solid $z;
					color: $z;
					padding: 10px 15px;
					transition:0.3s;
				}
				a:hover
				{
					background: $z;
					color: #fff;
				}
			}
		}
	}
}
.aboutUs_pc_box
{
	margin:80px auto 20px;
	width: 100%;
	min-height: calc(100vh - 262px - 55px);
	.aboutUs_pc
	{
		display: grid;
		width: 100%;
		max-width: 1200px;
		margin: 20px auto 0;
		grid-template-columns:30% 5% 20% 45%;
		grid-template-rows:auto auto;
		li.m1
		{
			grid-row-start:1;
			grid-row-end:3;

		}
		.m2
		{
			grid-row-start:1;
			grid-row-end:3;
		}
		li
		{
			overflow: hidden;
			position:relative;
			>img
			{
				width: 100%;
				height: 100%;
			}
			.info
			{
				width: 90%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform:translate(-50%,-50%);
			}
		}
	}
}
.mineBox
{
	margin:100px auto 0;
	padding-bottom: 55px;
	max-width: 1200px;
	width: 100%;
	min-height: calc(100vh - 262px - 55px);
	.head
	{
		background: linear-gradient(#33e6bd,#2bbf9d);
		padding:40px 0;
		color: #fff;
		text-align: center;
		img
		{
			width: 60px;
			height: 60px;
			border-radius: 50%;
			margin:0 auto;
		}
		.name
		{
			font-size: 16px;
			margin-top: 12px;
		}
	}
	.nav_s
	{
		display: flex;
		justify-content:space-between;
		.l
		{
			width:240px;
		}
		.r
		{
			width: calc(100% - 260px);
			box-sizing:border-box;
			padding: 20px;
			background: #fff;
			margin-top: 10px;
			.tit
			{
				display: flex;
				justify-content:space-between;
				align-items:center;
				.txBtn
				{
					padding: 5px 10px;
					background: $z;
					color: #fff;
					border-radius: 5px;
				}
			}
		}
	}
	@at-root .modul
	{
		background: #fff;
		margin-top: 10px;
		h3
		{
			height: 40px;
			line-height: 40px;
			border-bottom: 1px solid #eee;
			font-size: 14px;
			padding:0 12px; 
			a
			{
				float: right;
				font-size: 12px;
				color: $z;
			}
		}
		.list
		{

			li
			{
				height: 44px;
				line-height: 44px;
				padding: 0 12px;
				position: relative;
				cursor: pointer;
				i
				{
					font-size: 18px;
					margin-right: 5px;
					color: $z;
				}
				i,em
				{
					display: inline-block;
					vertical-align: middle;
				}
			}
			li.sel
			{
				background: $z;
				color: #fff;
				i
				{
					color: #fff;
				}
			}
			li:active
			{
				background: #eee;
			}
			li:not(:nth-last-of-type(1))
			{
				border-bottom:1px solid #eee;
			}
			li:after
			{
				font-family: 'iconfont';
				font-size: 12px;
				color: #ccc;
				content: '\e62f';
				position: absolute;
				top: 50%;
				right: 12px;
				transform:translate(0,-50%);
			}
			li.ds
			{
				display: flex;
				justify-content:space-between;
				b
				{
					font-size: 12px;
					color: #666;
				}
			}
			li.ds:after
			{
				content: '';
			}
		}
		.orderInfo
		{
			display:flex;
			justify-content:space-between;
			text-align: center;
			a
			{
				font-size: 12px;
				padding:10px 0;
				flex:1;
				.yellow
				{
					color: #d4b03e;
				}
				.pink
				{
					color: #f280a0;
				}
				.blue
				{
					color: #25a3d7;
				}
				.green
				{
					color: #34c11e;
				}
				i,em
				{
					display: block;
				}
				i
				{
					font-size:30px;
					margin-bottom: 4px;
				}
			}
		}
	}
}
.award
{
	border-top: 1px solid rgba(0,0,0,0);
	.head
	{
		padding:40px 0;
		display:flex;
		justify-content:space-around;
		align-items:center;
		background: #fff;
		margin-top: 10px;
		box-shadow: 0 0 3px rgba(0,0,0,0.1);
		dt
		{
			em,b
			{
				display: block;
			}
			em
			{
				color: #666;
			}
			b
			{
				color: #e51c23;
				margin-top: 5px;
			}
		}
	}
	.list
	{
		background: #fff;
		box-shadow: 0 0 3px rgba(0,0,0,0.1);
		margin-top: 10px;
		li
		{
			display:flex;
			justify-content:space-between;
			align-items:center;
			height:44px;
			line-height:44px;
			padding: 0 12px;
			em
			{
				flex:1;
			}
			em:nth-of-type(2)
			{
				color: #666;
			}
			em:nth-of-type(3)
			{
				text-align: right;
			}
			.red
			{
				color: #e51c23;
			}
			.green
			{
				color: $z;
			}
		}
		li:not(:nth-last-of-type(1))
		{
			border-bottom: 1px solid #eee;
		}
	}
}
.card
{
	.bankPc
	{
		display:flex;
		align-items:center;
		color: #666;
		.name
		{
			font-size: 16px;
			color: #000;
		}
		img
		{
			width: 50px;
			height: 50px;
			margin-right: 20px;
		}
	}
	>button.iconfont
	{
		height: 44px;
		background: #fff;
		margin: 10px 0;
		box-shadow: 0 0 2px rgba(0,0,0,0.1);
		position: relative;
		padding: 10px 20px;
	}
	.box
	{
		background: #d8243b;
		width:320px;
		height:180px;
		border-radius: 4px;
		position: relative;
		.cardInfo
		{
			position: absolute;
			width: 86%;
			top: 50%;
			left: 50%;
			transform:translate(-50%,-50%);
			color: #fff;
			.nub
			{
				font-size: 22px;
				margin: 20px 0;
			}
			.top
			{
				dt
				{
					display: inline-block;
					vertical-align: middle;
				}
				dt.u
				{
					width: 40px;
					height: 40px;
					border-radius: 50%;
					overflow: hidden;
				}
			}
			.d
			{
				display: flex;
				justify-content:space-between;
				.del
				{
					background: #fff;
					color: #333;
					padding:5px 8px;
					border-radius: 2px; 
				}
			}
		}
	}
	.addCardInfo
	{
		position: fixed;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
		background: #fff;
		width:420px;
		z-index:120;
		transition:0.3s;
		//display: none;
		h3
		{
			height: 50px;
			line-height: 50px;
			padding: 0 0 0 12px;
			border-bottom: 1px solid #eee;
			font-weight: 700;
			button
			{
				width: 50px;
				height: 50px;
				float: right;
				font-size: 20px;
			}
		}
		.submit
		{
			height: 44px;
			background: $z;
			color: #fff;
			font-size: 16px;
			width: 100%;
		}
		.submit:active
		{
			background: #1ea787;
		}
		ul
		{
			li
			{
				padding: 0 12px;
				height: 44px;
				line-height: 44px;
				border-bottom: 1px solid #eee;
				font-size: 0;
				em,input
				{
					display: inline-block;
					vertical-align: middle;
					font-size: 14px;
				}
				em
				{
					width: 80px;
					text-align: right;
					margin-right: 10px;
				}
				input
				{
					height: 44px;
					width: calc(100% - 90px);
					text-align: left;
				}
			}
		}
	}
	.bank
	{
		box-sizing:border-box;
		z-index:101;
		height:calc(100vh - 300px);
		position: fixed;
		top:50%;
		left:50%;
		background:rgba(0,0,0,0.7);
		transform:translate(-50%,-50%);
		width:400px;
		z-index:130;
		padding: 20px;
		transition:0.3s;
		.bankList
		{
			overflow-y: scroll;
			height: 100%;
			background: #fff;
			li
			{
				height:60px;
				line-height:60px;
				padding: 0 12px;
				border-bottom: 1px solid #eee;
				position: relative;
				cursor: pointer;
				img,em
				{
					display:inline-block;
					vertical-align: middle;
				}
				img
				{
					width:30px;
					height:30px;
				}
			}
			li:after
			{
				content: '';
				display: block;
				width:15px;
				height:15px;
				border-radius: 50%;
				border:1px solid #ccc;
				position: absolute;
				right: 12px;
				top: 50%;
				transform:translate(0,-50%);
				background-clip:content-box;
				padding:5px;
			}
			li.sel:after
			{
				background-color: #d8243b;
			}
			li:active
			{
				background: #eee;
			}
		}
	}
}
.address_pc
{
	width: 100%;
	.list
	{
		li
		{
			padding: 20px 250px 20px 10px;
			position: relative;
			border-bottom: 1px solid #eee;
			color: #666;
			overflow: hidden;
			.userInfo
			{
				display: flex;
				dt
				{
					padding-right: 15px;
					font-size:18px;
				}
			}
			.add
			{
				cursor: pointer;
				padding: 10px 0;
				i
				{
					display: inline-block;
					vertical-align: middle;
					width: 16px;
					height: 16px;
					line-height: 16px;
					text-align: center;
					border-radius: 50%;
					border:1px solid #ccc;
					font-size: 12px;
				}
			}

			.btns
			{
				position: absolute;
				top: 50%;
				right: 10px;
				transform:translate(0,-50%);
				button
				{
					padding: 3px 8px;
					color: #666;
				}
				button.moren
				{
					color: $z;
				}
			}
		}
		.sel
		{
			color: $z;
			.userInfo
			{
				font-weight: 700;
			}
		}
	}
	.selSF
	{
		background: #fff;
		padding:20px 12px;
		margin-top: 12px;
		li
		{
			padding:10px 0;
			input,select
			{
				height:40px;
				border:1px solid #eee;
				margin-right: 15px;
				border-radius: 5px;
				padding: 0 10px;
				min-width:180px;
				box-sizing:border-box; 
			}
			.xx
			{
				min-width:600px;
			}
		}

		.btns
		{
			button
			{
				margin-right: 15px;
				height: 40px;
				border-radius: 5px;
				background: #ccc;
				min-width: 120px;
			}
			button.sel
			{
				background: $z;
				color: #fff;
			}
		}
	}
}
.accunt_pc
{
	.labs
	{
		button
		{
			min-width: 70px;
			padding:10px 15px;
			margin-right: 10px;
			border-bottom:2px solid rgba(0,0,0,0);
		}
		button.sel
		{
			border-bottom:2px solid $z;
		}
	}
	.nubCard
	{
		margin-top: 30px;
		width: 320px;
		dt
		{
			padding: 2px 0;
			font-size: 18px;
			display: flex;
			justify-content:space-between;
			align-items:center;
			b
			{
				color:#d8243b;
			}
		}
	}
	.list
	{
		input[put="text"]
		{
			width: 300px;
			line-height: 40px;
			border:1px solid #eee;
			margin-bottom: 10px;
			padding: 0 5px;
			border-radius: 4px;
			display: block;
		}
		li
		{
			padding: 20px 0;
			.note
			{
				font-size: 12px;
				color: #666;
			}
			.subBtn
			{
				min-width: 160px;
				padding: 0 15px;
				height: 40px;
				background: $z;
				color: #fff;
				margin-top: 20px;
				border-radius: 5px;
			}
			.yzm
			{
				font-size: 0;
				input
				{
					font-size: 14px;
					width:180px;
					display: inline-block;
					vertical-align: middle;
				}
				button
				{
					font-size: 14px;
					background: #eee;
					height: 40px;
					width: 110px;
					margin-left: 10px;
					border-radius: 5px;
				}
				button.sel
				{
					background: $z;
					color: #fff;
				}
			}
		}
		.headImg
		{
			button
			{
				background: $z;
				color: #fff;
				margin-top: 20px;
				width: 100px;
				height: 40px;
				position: relative;
				border-radius: 5px;
				input
				{
					position: absolute;
					top: 0;
					width: 100%;
					height: 100%;
					left: 0;
					opacity:0;
					cursor: pointer;
				}
			}
			img
			{
				width:100px;
				height:100px;
				border-radius: 50%;
			}

		}
	}
}
.card_pc
{
	margin: 100px auto 50px;
	min-height: calc(100vh - 162px - 150px);
	max-width:1200px;
	width: 100%;
	background: #fff;
	.list
	{
		li
		{
			display: flex;
			padding: 20px 30px;
			align-items:center;
			justify-content:space-between;
			border-bottom: 1px solid #eee;
			.ico
			{
				width: 50px;
				font-size: 22px;
				height: 50px;
				color:#666;
			}
			.sel
			{
				color:$z;
			}
			.oImg
			{
				width: 100px;
			}
			h3
			{
			    max-width: 50%;
			}
			.btns
			{
				border:1px solid #eee;
				white-space: nowrap;
				button
				{
					width:60px;
					height:40px;
				}
			}
			.del
			{
				font-size: 20px;
				padding: 5px;
			}
			em
			{
				color: #c90007;
			}
		}
	}
}
.orderInfo
{
	padding: 0 20px;
	text-align: right;
	margin-top: 20px;
	dt
	{
		padding: 15px 0;
		font-size: 18px;
		button
		{
			background: $z;
			color: #fff;
			padding: 15px 30px;
			border-radius: 5px;
			font-size: 18px;
		}
	}
}
.orderList
{
	border-top: 1px solid #eee;
	li
	{ 
		padding:0 12px;
		background: #fff;
		margin-top: 10px;
		box-shadow: 0 0 3px rgba(0,0,0,0.1);
		h3
		{
			height: 40px;
			line-height: 40px;
			font-size: 14px;
			border-bottom: 1px solid #eee;
			display: flex;
			justify-content:space-between;
			.l	
			{
				float: left;
				dt
				{
					display: inline-block;
					vertical-align: middle;
					margin-right: 20px;
				}
				.n
				{
					span
					{
						color: $z;
					}
					.t
					{
						font-size: 14px;
						color: #999;
					}
				}
			}
			b
			{
				color:$z;
				font-size: 12px;
			}
		}
		.bom
		{
			text-align: right;
			padding: 10px 0;
			dt
			{
				padding:5px 0;
				button
				{
					border:1px solid #eee;
					margin-left: 10px;
					height:30px;
					padding: 0 10px;
					border-radius: 2px;
					overflow: hidden;
				}
				button.green
				{
					color: #fff;
					background: $z;
					border:1px solid $z;
				}
				button.green
				{
					color: #fff;
					background: $z;
					border:1px solid $z;
				}
				button.del
				{
					background: #c10505;
					color: #fff;
				}
			}
		}
		.info
		{
			display: flex;
			align-items:center;
			justify-content:space-between;
			border-bottom: 1px solid #eee;
			padding:30px 0;
			img
			{
				width: 80px;
			}
			dl
			{
				width: calc(100% - 90px);
				font-size: 12px;
				.name,
				.indu
				{
					overflow: hidden;
					text-overflow:ellipsis;
					display: -webkit-box;
					-webkit-box-orient:vertical;
					-webkit-line-clamp:1;
				}
				.name
				{
					color: #000;
					font-size: 14px;
					margin-bottom:4px;
				}
				.indu
				{
					color: #666;
				}
				.d
				{
					display: flex;
					align-items:center;
					justify-content:space-between;
					padding-top: 10px;
					font-size: 18px;
					em
					{
						color: #b90f20;
					}
				}
			}
		}
	}
	.detailsInfo
	{
		dl
		{
			dt
			{
				display: flex;
				justify-content:space-between;
				height: 44px;
				line-height: 44px;
				border-bottom: 1px solid #eee;
			}
		}
	}
}
.allOrder
{
	max-width: 1200px;
	width: 100%;
	margin: 80px auto 20px;
	border-top: 1px solid rgba(0,0,0,0);
}
.labsBox_t
{
	display: flex;
	justify-content:space-around;
	background: #fff;
	width: 100%;
	box-shadow: 0 1px 2px rgba(0,0,0,0.2);
	margin-top: 10px;
	button
	{
		border-bottom: 1px solid rgba(255,255,255,0);
		padding: 0 25px;
		height:60px;
		color: #666;
	}
	button.sel
	{
		color: $z;
		font-weight: 700;
		border-bottom: 2px solid $z;
	}
	button:active
	{
		background: #eee;
	}
}
.partOrder_pc
{
	margin: 100px auto 40px;
	max-width: 1200px;
	width: 100%;
}
.partOrder
{
	margin: 100px auto 40px;
	max-width: 1200px;
	width: 100%;
	min-height: calc(100vh - 262px - 60px);
}
.addressHead
{
	.have
	{
		background: #fff;
		padding:30px 12px; 
		position: relative;
		display:flex;
		cursor: pointer;
		i
		{
			width: 24px;
			line-height: 24px;
			color: $z;
		}
		.name
		{
			width: calc(100% - 30px);
		}
		button.iconfont
		{
			font-size: 22px;
		}
		button.iconfont.sel
		{
			color: $z;
		}
	}
	.have.modify
	{
		padding-right: 40px;
	}
	.have.modify:after
	{
		position: absolute;
		top: 50%;
		right:12px;
		transform:translate(0,-50%);
		content: '\e62f';
		display: block;
		font-family: 'iconfont';
		color: #999;
	}
	.add
	{
		text-align: center;
		line-height:60px;
		background: #fff;
		height:60px;
		text-align: center;
		border-top: 1px solid #eee;
		cursor: pointer;
	}
}
.payModeOrder,
.paySuccess
{
	border:1px solid #ccc;
	max-width:1200px;
	width:100%;
	margin:100px auto 40px;
	background: #fff;
	min-height: calc(100vh - 262px - 40px);
	.clearingSure
	{
		overflow: hidden;
		padding: 40px 0;
		text-align: center;
		i,dl
		{
			display: inline-block;
			vertical-align: middle;
		}
		i
		{
			font-size:100px;
			color: $z;
			height: 100px;
			width: 100px;
		}
		dl
		{
			text-align: left;
			padding-left: 20px;
			dt
			{
				padding: 5px 0;
			}
			span
			{
				color: $z;
			}
			b
			{
				color:red;
				font-size: 18px;
			}
		}
	}
	.payMode
	{
		clear:both;
		overflow: hidden;
		padding: 20px;
		border-top: 1px solid #eee;
		li
		{
			border:#eee solid 1px; 
			float: left;
			margin-right: 10px;
			height: 40px;
			button,img
			{
				display: inline-block;
				vertical-align: middle;
			}
			button
			{
				padding: 0 10px;
				font-size: 18px;
				color: #999;
			}
			img
			{
				width: auto;
				height: 100%;
			}
			button.sel
			{
				color: $z;
			}
		}
	}
	.btn
	{
		padding:20px 0;
		text-align: center;
		border-top: 1px solid #eee;
		button,a
		{
			width: 100px;
			height: 30px;
			background: $z;
			color: #fff;
			font-size: 14px;
			margin: 0 10px;
			display: inline-block;
			vertical-align:middle;
			line-height: 30px;
			border-radius: 2px;
		}
		a
		{
			background: #eeelue;
		}
		a.Y
		{
			background: $z;
		}
	}
}
.sign_in_pc
{
	min-height: calc(100vh - 80px);
	background: #dde1ec;
	position: relative;
	.sign_box
	{
		width: 320px;
		h1
		{
			color: #333;
		}
	}
	
	.info
	{
		position: absolute;
		top: 50%;
		left: 50%;
		max-width: 1200px;
		width: 100%;
		display: flex;
		justify-content:space-between;
		align-items:center;
		transform:translate(-50%,-50%);
		dt.img
		{
			max-width:500px;
		}
		dt.main_s
		{
			box-shadow: 0 0 10px rgba(0,0,0,0.5);
		}
	}
}
.logo_sign
{
	position: absolute;
	top: 40px;
	left:80px;
	width:80px;
	height:80px;
}
.sign_up_pc
{
	min-height: calc(100vh - 80px);
	position: relative;
	.sign_box
	{
		width: 380px;
	}
	h1
	{
		color: $z;
	}
	.info
	{
		position: absolute;
		top: 50%;
		left: 50%;
		transform:translate(-50%,-50%);
	}
}
.sign_box
{
	background: #fff;
	padding: 40px;
	text-align: center;
	.name
	{
		margin-bottom: 30px;
	}
	h1
	{
		font-weight: 700;
		padding: 20px 0 30px;
	}
	ul
	{
		li
		{
			margin: 10px 0;
			position: relative;
			button.code
			{
				width:90px;
				position: absolute;
				height: 100%;
				top: 0;
				right: 0;
				border-left: 1px solid #999;
			}
			button.code.sel
			{
				color: $z;
			}
			input
			{
				width: 100%;
				border:1px solid #999;
				height: 40px;
				padding: 5px 10px;
				box-sizing:border-box;
			}
		}
		.oImg
		{
			display: flex;
			input
			{
				flex:1;
			}
			.oImg
			{
				width: 90px;
				height:40px;
				margin-left: 10px;
				cursor: pointer;
			}
		}
	}
	.forget
	{
		display: flex;
		justify-content:space-between;
		align-items:center;
		color: #666;
		margin: 20px 0;
		.fa
		{
			a
			{
				color: $z;
			}
		}
		.selBtn
		{
			width:20px;
			height:20px;
			border:1px solid #999;
			position: relative;
			vertical-align: middle;
			input[type="checkbox"]
			{
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				z-index: 1;
				margin: 0;
				opacity: 0;
				cursor: pointer;
			}
			img
			{
				display: none;
			}
			input[type="checkbox"]:checked + img
			{
				display: block;
			}
		}
	}
	.btns
	{
		button,a
		{
			height: 44px;
			line-height: 44px;
			border:1px solid #999;
			display: block;
			width: 100%;
			margin-top: 10px;
		}
		.sel
		{
			background: $z;
			color: #fff;
			border:1px solid $z;
		}
	}
}
.signFooter
{
	height:78px;
	text-align: center;
	border-top: 1px solid rgba(255,255,255,0);
	.up
	{
		font-size: 16px;
		color: #333;
		margin-top: 10px;
	}
}
.productDetails_pc
{
	margin:100px auto 40px;
	max-width:1000px;
	width: 100%;
	.head
	{
		display: flex;
		justify-content:space-between;
		background: #fff;
		padding: 40px;
		margin-bottom:20px;
		dt.l
		{
			width: 40%;
			.bigImg
			{
				padding:10px;
				border:1px solid #eee;
				box-sizing:border-box;
				img
				{
					width: 100%;
				}
			}
			ul
			{
				display: flex;
				justify-content:space-between;
				margin-top: 10px;
				li
				{
					width:30%;
					height:30%;
					border:1px solid #eee;
					box-sizing:border-box;
					padding: 5px;
					cursor: pointer;
				}
			}
		}
		dt.r
		{
			width:calc(60% - 40px);
			h1
			{
				color: #000;
				font-weight: 700;
			}
			.fbt
			{
				border-bottom: 1px solid #eee;
				padding:10px 0 30px;
			}
			.price
			{
				font-size:30px;
				color: #cc0000;
				margin: 40px 0;
				s
				{
					font-size: 14px;
					color: #666;
				}
			}
			.n
			{
				.btns
				{
					display: inline-block;
					border:1px solid #ccc;
					span,button
					{

					}
					button
					{
						padding: 10px 25px;
						font-size: 20px;
					}
				}
			}
			.btn_btns
			{
				padding-top: 60px;
				button
				{
					height:40px;
					width:120px;
					background: #eee;
					border:1px solid #ccc;
					border-radius: 4px;
					margin-right: 15px;
					font-size: 14px;
				}
				.buy
				{
					background: #2cbf9e;
					color: #fff;
				}
			}
		}
	}
	.content
	{
		margin-top: 40px;
		background: #fff;
		padding: 20px;
		.scrollTxt
		{
			width: 100%;
			overflow: hidden;
		}
	}
}
.newsDetails_pc
{
	margin: 100px auto 40px;
	width:100%;
	max-width: 1200px;
	min-height: calc(100vh - 262px - 40px);
	text-align: center;
	h1
	{
		font-size: 30px;
		color: #000;
	}
	.time
	{
		padding: 20px 0;
		font-size: 16px;
		color: #666;
		border-bottom: 1px solid #eee;
	}
	.content
	{
		padding-top: 10px;
	}
}
.category
{
	height: 50px;
	background:#fff;
	line-height: 50px;
	margin-bottom: 20px;
	li
	{
		float:left;
		padding:0 15px;
		cursor: pointer;
	}
	.searchPut
	{

		float: right;
		input
		{
			border:1px solid #ccc;
			padding: 0 3px;
			border-radius: 3px;
			height:30px; 
		}
		button
		{
			padding: 0 15px;
			height:30px; 
			font-size: 18px;
			color: #333;
		}
	}
	li:not(.searchPut):hover
	{
		background: #f8f8f8;
	}
	li.sel
	{
		background: $z;
		color: #fff;
	}
}

.pager
{
	clear: both;
	padding: 40px 0 20px;
	text-align: center;
	a
	{
		display: inline-block;
		vertical-align: middle;
		width:40px;
		height:40px;
		line-height:42px;
		font-size: 14px;
		text-align: center;
		background: #fff;
		margin: 0 2px;
		border:1px solid #f5f3f3;
	}
	a:hover
	{
		background:rgba(16,168,225,0.1);
	}
	a.sel
	{
		background:rgba(16,168,225,1);
		color: #fff;
		border:1px solid rgba(16,168,225,1);
	}
}
.commentSubmit
{
	background: #fff;
	padding:50px;
	overflow: hidden;
	margin:100px auto 40px;
	padding-bottom: 20px;
	max-width: 1200px;
	width: 100%;
	box-sizing:border-box;
	textarea
	{
		width: 100%;
		min-height:180px;
		resize:none;
		padding: 20px;
		box-sizing:border-box;
	}
	ul.com
	{
		overflow: hidden;
		margin-bottom:30px;
		li
		{
			width:80px;
			height:80px;
			float: left;
			border:1px  dashed #eee;
			margin-right: 6px;
			color: #999;
			position: relative;
			input
			{
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				cursor: pointer;
			}
			i,em
			{
				display: block;
				text-align: center;
			}
			i
			{
				height: 30px;
				line-height: 30px;
				font-size:20px;
				margin-top:15px; 
			}
			em
			{
				font-size: 8px;
			}
		}
	}
}
button.submitPosBom
{
	height: 44px;
	background: #fff;
	width:calc(100vw - 40px);
	box-shadow: 0 0 2px rgba(0,0,0,0.1);
	bottom:20px;
	left:50%;
	background: $z;
	color: #fff;
	border-radius: 4px;
	width: 120px;
}
.notePop
{
	border-radius:4px;
	position: fixed;
	width:400px;
	background: #fff;
	top: 50%;
	left: 50%;
	transform:translate(-50%,-50%);
	overflow: hidden;
	z-index: 222;
	.know 
	{
		width: 100%;
		height: 44px;
		background: $z;
		color:#fff;
	}
	h3
	{
		height: 40px;
		line-height: 40px;
		padding:0 0 0 12px;
		font-size: 12px;
	}
	p
	{
		text-align: center;
		padding:20px 10px;
		font-size: 12px;
		color: #666;
	}
	dl dt
	{
		width: 50%;
		float: left;
		box-sizing: border-box;;
		font-size: 12px;
		border-top: 1px solid #ccc;
		height: 40px;
		line-height: 40px;
		text-align: center;
		color:#666;
		cursor: pointer;
	}
	dl dt:nth-of-type(2)
	{
		border-left: 1px solid #ccc;
	}
	h3 button
	{
		color: #999;
		width: 40px;
		height: 40px;
		font-size: 16px;
		float: right;
	}
}
.noData img
{
	width:260px;
	display: block;
	padding:20px 0;
	margin: 0 auto;
}
.notePop .sure
{
	background:#2683c8;
	color: #fff;
}
.banner
{
	margin: 80px 0 30px;
}
.contentTit
{
	display: flex;
	justify-content:space-between;
	align-items:center;
	height: 44px;
	padding: 0 12px;
	line-height: 44px;
	background: #fff;
	border-bottom: 1px solid #eee;
	a
	{
		color: $z;
	}
}
.comment
{
	background: #fff;
	li
	{
		padding:20px 40px;
		overflow: hidden;
		border-bottom: 1px solid #eee;
		.name
		{
			img,em
			{
				display: inline-block;
				vertical-align: middle;
				font-size: 16px;
			}
			img
			{
				width: 40px;
				height: 40px;
				border-radius: 50%;
				margin-right: 10px;
			}
		}
		b
		{
			display: block;
			color: #999;
			font-size: 12px;
			margin: 5px 0;
		}
		.imgs
		{
			margin-top: 12px;
			img
			{
				width:50px;
				height:50px;
				float: left;
				margin-right: 5px;
			}
		}
	}
}
.comment_box
{
	width: 100%;
	max-width: 1200px;
	margin: 100px auto 20px;
	min-height: calc(100vh - 162px - 100px - 20px)
}
.popCode
{
	position: fixed;
	top: 50%;
	left: 50%;
	transform:translate(-50%,-50%);
	z-index:108;
	.code
	{
		width: 200px;
		height: 200px;
		padding: 40px;
		background: #fff;
	}
	.nTxt
	{
		height: 44px;
		line-height: 44px;
		text-align: center;
		background: #fff;
	}
}
.titTxt
{
	font-size:40px;
	font-weight: 700;
	padding: 40px 0;
	letter-spacing: 5px;
	position: relative;
	text-align: center;
}
.titTxt:after
{
	content: '';
	display: block;
	background: #333;
	position: absolute;
	height: 6px;
	width: 60px;
	top:10px;
	left: 50%;
	transform:translate(-50%,-50%);
}
.shareCode
{
	width: 280px;
	z-index:1 ;
	margin: 40px auto;
}
.add_l_n
{
	border-top: 1px solid #eee;
}